import classNames from "classnames";
import PropTypes from "prop-types";

function ButtonWithIcon({
  onClick,
  btnText,
  className,
  isLoading,
  isDisabled,
  Icon,
  InlineLoaderIcon,
}) {
  return (
    <button
      className={`relative flex cursor-pointer items-center justify-center border ${className}`}
      disabled={isDisabled || isLoading}
      onClick={onClick}
    >
      {isLoading && InlineLoaderIcon}
      {Icon}
      <p
        className={classNames({
          invisible: isLoading,
          visible: !isLoading,
        })}
      >
        {btnText}
      </p>
    </button>
  );
}

ButtonWithIcon.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  btnText: PropTypes.string.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  Icon: PropTypes.node,
  InlineLoaderIcon: PropTypes.node,
};

ButtonWithIcon.defaultProps = {
  isLoading: false,
  isDisabled: false,
  Icon: null,
  className: "h-[2.375rem] gap-[1rem] w-full rounded-lg",
  onClick: () => {},
};

export default ButtonWithIcon;
