import classNames from "classnames";

import { PRODUCT_LABEL_MAP } from "@/utils/constants";

function ProductLabel({ label, type, className }) {
  return (
    <div
      className={classNames(
        {
          "bg-[#B75FEF]": type === PRODUCT_LABEL_MAP.FREE_DELIVERY,
          "bg-[#FF912C]": type === PRODUCT_LABEL_MAP.PERCENTAGE_DISCOUNT,
          "bg-[#B75FEF] ": type === PRODUCT_LABEL_MAP.HOT,
          "bg-[#FF912C] ": type === PRODUCT_LABEL_MAP.NEW,
          " bg-[#B75FEF]": type === PRODUCT_LABEL_MAP.FEATURED,
          " bg-[#FF912C]": type === PRODUCT_LABEL_MAP.COUPON_DISCOUNT,
          " bg-[#B75FEF] ": type === PRODUCT_LABEL_MAP.POPULAR,
        },
        `rounded-[0.313rem] px-[0.313rem] py-[0.188rem] text-[0.75rem] font-semibold text-white sm:px-[0.5rem] sm:py-[0.313rem] sm:text-[0.813rem] ${className}`
      )}
    >
      {label}
    </div>
  );
}

ProductLabel.defaultProps = {
  className: "",
};

export default ProductLabel;
