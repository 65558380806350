export const updateListWithWishlistStatus = (productList, wishlistMap) => {
  return productList.map((product) => ({
    ...product,
    is_whitelisted: Object.hasOwn(wishlistMap, product.slug),
  }));
};

export const updateSingleWithWishlistStatus = (productSlug, wishlistMap) => {
  return {
    is_whitelisted: Object.keys(wishlistMap).includes(productSlug),
  };
};

export const updateCategoryListWithWishlistStatus = (
  categoryList,
  wishlistMap
) => {
  const updatedCategoryList = [];

  categoryList.forEach((category) => {
    const updatedProducts = updateListWithWishlistStatus(
      category?.products,
      wishlistMap
    );
    updatedCategoryList.push({
      ...category,
      products: updatedProducts,
    });
  });

  return updatedCategoryList;
};
