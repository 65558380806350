import classNames from "classnames";
import PropTypes from "prop-types";

import {
  CartIcon,
  InlineLoaderIcon as InlineLoader,
} from "@/components/data-display/icon";

import ButtonWithIcon from "../btnWithIcon";

function AddToCartButton({
  onClick,
  isIconVisible,
  className,
  isLoading,
  isDisabled,
  InlineLoaderIcon,
}) {
  return (
    <ButtonWithIcon
      className={classNames(
        {
          "bg-disable-bg text-disable-text": isDisabled,
        },
        {
          [`${className} border-tertiary bg-white text-tertiary`]: !isDisabled,
        },
        `h-[2.375rem] w-full rounded-[0.5rem] text-[0.813rem] font-semibold leading-[1.5rem] sm:h-[3rem] sm:rounded-[0.625rem] sm:text-[1rem]`
      )}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onClick}
      Icon={
        isIconVisible ? (
          <CartIcon
            className={classNames(
              {
                invisible: isLoading,
                visible: !isLoading,
              },
              "h-[2.625rem] w-[2.625rem]"
            )}
          />
        ) : null
      }
      btnText="Add to Cart"
      InlineLoaderIcon={InlineLoaderIcon}
    />
  );
}

AddToCartButton.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isIconVisible: PropTypes.bool,
  InlineLoaderIcon: PropTypes.node,
};

AddToCartButton.defaultProps = {
  isLoading: false,
  isDisabled: false,
  isIconVisible: true,
  onClick: () => {},
  InlineLoaderIcon: (
    <InlineLoader className="absolute left-[calc(50%_-_1.5rem)] top-[calc(50%_-_1.5rem)] inline-block h-[3rem] w-[3rem] text-primary sm:!text-white" />
  ),
};

export default AddToCartButton;
