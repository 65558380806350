import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Image from "next/image";
import Link from "next/link";

import classNames from "classnames";
import PropTypes from "prop-types";

import { useAddToCart } from "@/api/client/cart";
import { useGetNotificationsData } from "@/api/client/notifications";
import {
  useAddWishlistData,
  useRemoveWishlistData,
} from "@/api/client/wishlist";
import AddToCartButton from "@/components/actions/button/addToCartBtn";
import {
  WishListWithBackgroundIcon,
  WishlistActiveIcon,
} from "@/components/data-display/icon";
import ProductLabel from "@/components/data-display/label/productLabel";
import { errorToast, successToast } from "@/components/feedback/toast";
import { fbEvents, sendFPixelEvent } from "@/lib/fpixel";
import { updateCartItemCount } from "@/store/feature/cartSlice";
import { updateWishlistItemCount } from "@/store/feature/wishlistSlice";
import { isAuthorizedUser } from "@/utils/helpers";

import { StockOutBadge } from "../../StockOutBadge";
import Rating from "../../rating";
import Styles from "./Product.module.css";

function ProductCard({ product: propProductData }) {
  const {
    name,
    promotions,
    is_whitelisted,
    slug,
    image,
    product_rating,
    product_rating_count,
    has_discount_price,
    price,
    discount_price,
    current_stock,
  } = propProductData;
  const { refetch: refetchNotificaitonData } = useGetNotificationsData({
    enabled: false,
  });

  const [isWishlist, setIsWishlist] = useState(is_whitelisted);
  const dispatch = useDispatch();

  const { mutate: addToWishlist, isLoading: isAddingToWishlist } =
    useAddWishlistData({
      onSuccess: (res) => {
        setIsWishlist(true);
        successToast({ message: res.message });
        sendFPixelEvent(fbEvents.addToWishList);

        dispatch(updateWishlistItemCount(res?.wishlist?.length));
      },
      onError: (errorData) => {
        errorToast({ message: errorData.message });
      },
    });

  const { mutate: removeWishlist, isLoading: isRemovingFromWishlist } =
    useRemoveWishlistData({
      onSuccess: (res) => {
        setIsWishlist(false);
        successToast({ message: res.message });
        refetchNotificaitonData();
      },
      onError: (errorData) => {
        errorToast({ message: errorData.message });
      },
    });

  useEffect(() => {
    setIsWishlist(propProductData.is_whitelisted);
  }, [propProductData]);

  const { mutate: addToCart, isLoading: isAddToCartLoading } = useAddToCart({
    onSuccess: ({ cartData }) => {
      successToast({ message: "Added to cart successfully" });
      dispatch(updateCartItemCount(cartData?.cart?.products?.length));
    },
    onError: (errorData) => {
      errorToast({ message: errorData.message });
    },
  });

  const handleWishlist = async () => {
    const isAuthorized = await isAuthorizedUser();
    if (!isAuthorized) {
      return;
    }

    if (!isWishlist) {
      addToWishlist({ product: slug });
    } else {
      removeWishlist({ product: slug });
    }
  };

  const handleCart = async () => {
    if (current_stock < 1) {
      return;
    }

    addToCart({
      product: slug,
      quantity: 1,
    });
  };

  return (
    <>
      <div className="group relative w-full rounded-[0.625rem] opacity-[0.9]">
        <div className="flex flex-col justify-between rounded-[0.625rem] border-2 border-transparent transition delay-150 duration-300 ease-in-out sm:group-hover:border-[#EDF3F7]">
          <Link href={`/product/${slug}`}>
            <div className="flex aspect-square w-full cursor-pointer items-center justify-center rounded-t-[0.625rem] transition delay-150 duration-300 ease-in-out sm:group-hover:bg-[#F5F8FA]">
              <div className="relative h-full w-full">
                {image && (
                  <Image
                    fill
                    alt={name}
                    src={image}
                    sizes="(max-width: 1024px) 50vw, 33vw"
                    className={classNames(
                      {
                        "opacity-30": current_stock < 1,
                      },
                      "rounded-t-[0.625rem] object-contain"
                    )}
                  />
                )}
              </div>
              {current_stock < 1 && <StockOutBadge />}
            </div>
          </Link>

          <div
            className={classNames(
              { "opacity-70": current_stock < 1 },
              "px-[0.625rem] py-[0.75rem] sm:px-[0.938rem] sm:py-[1rem]"
            )}
          >
            <div>
              <Link href={`/product/${slug}`}>
                <h3 className="line-clamp-2 h-[2.35rem] cursor-pointer text-[0.813rem] font-normal leading-[1.25rem] text-grayDisplay-900 sm:h-[2.9rem] sm:text-[1.063rem] sm:leading-[1.563rem]">
                  {name}
                </h3>
              </Link>

              <div className="mt-[0.813rem] flex min-h-[1.5rem] items-center space-x-[0.625rem] lg:mt-[1.25rem]">
                {product_rating_count > 0 && (
                  <>
                    <Rating ratingNumber={product_rating} />
                    <p className="text-grayDisplay-850">
                      ({product_rating_count})
                    </p>
                  </>
                )}
              </div>

              <p
                className={`${Styles.productCard} mt-[1rem] flex flex-wrap items-center sm:mt-[1.25rem]`}
              >
                <span className="mr-[0.625rem] text-[1rem] font-semibold leading-[1.063rem] text-grayDisplay-700 sm:text-[1.375rem] sm:leading-[1.063rem]">
                  ৳{has_discount_price ? discount_price : price}
                </span>
                {has_discount_price && (
                  <span className="text-[0.813rem] leading-[1.063rem] tracking-[0.07em] text-[#7B838F] line-through sm:text-[1rem] sm:tracking-[0.08em]">
                    ৳{price}
                  </span>
                )}
              </p>
            </div>

            <div className="mt-[1rem] sm:mt-[1.125rem]">
              <AddToCartButton
                isLoading={isAddToCartLoading}
                className="text-primary transition delay-150 duration-300 ease-in-out sm:hover:bg-primary-900 sm:group-hover:bg-primary sm:group-hover:text-white"
                onClick={handleCart}
                btnText="Add to Cart"
              />
            </div>
          </div>
        </div>
        {promotions.length > 0 && (
          <div className="absolute left-[0.625rem] top-[0.625rem] z-[100] flex flex-col items-start gap-[0.313rem] sm:left-[0.75rem] sm:top-[0.75rem] sm:gap-[0.5rem]">
            {promotions.map((promotion) => (
              <ProductLabel
                label={promotion.content}
                type={promotion.key}
                key={promotion.key}
              />
            ))}
          </div>
        )}

        <button
          onClick={handleWishlist}
          disabled={isAddingToWishlist || isRemovingFromWishlist}
          className="absolute right-[0.625rem] top-[0.75rem] cursor-pointer disabled:cursor-wait sm:right-[0.688rem] sm:top-[0.75rem]"
        >
          {isWishlist ? (
            <WishlistActiveIcon className="h-[2rem] w-[2rem] bg-transparent sm:h-[2.5rem] sm:w-[2.5rem]" />
          ) : (
            <WishListWithBackgroundIcon className="h-[2rem] w-[2rem] group-hover:block sm:h-[2.5rem] sm:w-[2.5rem] md:hidden " />
          )}
        </button>
      </div>
    </>
  );
}

export default ProductCard;

ProductCard.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    has_discount_price: PropTypes.bool,
    discount_price: PropTypes.number,
    current_stock: PropTypes.number,
    product_rating: PropTypes.number,
    product_max_rating: PropTypes.number,
    product_rating_count: PropTypes.number,
    promotions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        content: PropTypes.string,
      })
    ),
  }).isRequired,
  showDiscount: PropTypes.bool,
};

ProductCard.defaultProps = {
  showDiscount: true,
};
